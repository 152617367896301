import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Button, Layout } from '@components';
import { AiOutlineYoutube } from 'react-icons/ai';

const blockMapping = ([bonus, first, second]: [any, any, any]) => [
    {
        ...first,
        translations: {
            'cs-CZ': 'První blok',
            en: 'First block'
        }
    },
    {
        ...second,
        translations: {
            'cs-CZ': 'Druhý blok',
            en: 'Second block'
        }
    },
    {
        ...bonus,
        translations: {
            'cs-CZ': 'Bonusový blok',
            en: 'Bonus block'
        }
    }
];

const ListItem = ({ title, content, className = '' }) => {
    return (
        <tr>
            <td className={`sm:whitespace-nowrap ${className}`}>
                <h4>{title}</h4>
            </td>
            <td>{content}</td>
        </tr>
    );
};

const Films: React.FC<any> = ({ data, pageContext: { locale } }) => {
    return (
        <Layout lang={locale} path="/films">
            <h1 className="font-bold text-center contentBlock">
                {locale == 'en' ? 'List of films' : 'Seznam filmů'}
            </h1>
            <div className="text-center">
                {blockMapping(data.allStrapiFilm.group).map(
                    ({ fieldValue, nodes, translations }) => {
                        return (
                            <div key={fieldValue}>
                                <h2 className="alternative-style-header font-bold">
                                    {translations[locale]}
                                </h2>

                                <div className="text-center">
                                    {nodes?.map(film => {
                                        const crewObject = data.allStrapiCrewRole.nodes.reduce(
                                            (acc, cur) => (
                                                (acc[cur.strapi_id] = {
                                                    name: cur.Name,
                                                    people: []
                                                }),
                                                acc
                                            ),
                                            {}
                                        );
                                        const crew = crewObject;
                                        film.CrewMember.forEach(c => {
                                            c.crew_roles.forEach(role => {
                                                crew[
                                                    role.strapi_id + (locale == 'en' ? 0 : -1)
                                                ]?.people.push(c.Name);
                                            });
                                        });

                                        const crewList = Object.keys(crew)
                                            .filter(c => crew[c].people.length > 0)
                                            .map(c => crew[Number(c)]);

                                        return (
                                            <div
                                                key={film.id}
                                                id={film.Name}
                                                className="text-center contentBlock">
                                                <h3 className="pb-4">{film.Name}</h3>
                                                <div className="block mb-4 md:hidden">
                                                    <GatsbyImage
                                                        image={
                                                            film.Image?.localFile?.childImageSharp
                                                                ?.gatsbyImageData
                                                        }
                                                        alt={film.Name}
                                                        imgClassName="md:object-right object-contain"
                                                        imgStyle={{ objectFit: 'contain' }}
                                                    />
                                                </div>
                                                <p className="pb-4">{film.Description}</p>
                                                <div className="flex flex-row items-start w-full justify-evenly align-center">
                                                    <table className="w-full md:w-3/5 crew">
                                                        {crewList.map(({ name, people }) => (
                                                            <ListItem
                                                                key={name}
                                                                title={name}
                                                                content={people.join(', ')}
                                                            />
                                                        ))}

                                                        {film.CastMember.length > 0 ? (
                                                            <ListItem
                                                                // className="p-4"
                                                                title={
                                                                    locale == 'en'
                                                                        ? 'Cast'
                                                                        : 'Herci'
                                                                }
                                                                content={film.CastMember.reduce(
                                                                    (acc, cur) =>
                                                                        cur?.Name
                                                                            ? [...acc, cur.Name]
                                                                            : acc,
                                                                    []
                                                                ).join(', ')}
                                                            />
                                                        ) : null}
                                                    </table>
                                                    <div className="flex-shrink-0 hidden w-2/5 pt-2 ml-2 md:inline-block">
                                                        <GatsbyImage
                                                            className="p-0"
                                                            image={
                                                                film.Image?.localFile
                                                                    ?.childImageSharp
                                                                    ?.gatsbyImageData
                                                            }
                                                            alt={film.Name}
                                                            imgClassName="md:object-right object-contain"
                                                            imgStyle={{ objectFit: 'contain' }}
                                                        />
                                                    </div>
                                                </div>
                                                {film?.VideoLink && (
                                                    <Button to={film.VideoLink}>
                                                        video
                                                        <span style={{ width: '5px' }} />
                                                        <AiOutlineYoutube className="inline text-2xl" />
                                                    </Button>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    }
                )}
            </div>
        </Layout>
    );
};

export default Films;

export const query = graphql`
    query FilmQuery($locale: String!) {
        allStrapiCrewRole(filter: { locale: { eq: $locale } }) {
            nodes {
                strapi_id
                Name
            }
        }
        allStrapiFilm(sort: { order: ASC, fields: Index }, filter: { locale: { eq: $locale } }) {
            group(field: Block) {
                nodes {
                    ...STRAPI_FILM
                }
                fieldValue
            }
        }
    }

    fragment STRAPI_FILM on STRAPI_FILM {
        Block
        CastMember {
            Name
        }
        CrewMember {
            Name
            crew_roles {
                strapi_id
                Name
            }
        }
        Description
        Genre
        Image {
            localFile {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
        Index
        ShowVideoLink
        Name
    }
`;
